// Redux - a slice is a collection of reducer logic and actions for a single feature of our app

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountResponse } from "../types";

type State = {
  token: string | null;
  account: AccountResponse | null;
};

const initialState: State = { token: null, account: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthTokens(
      state: State,
      action: PayloadAction<{ token: string; }>
    ) {
      state.token = action.payload.token;
    },
    setAccount(state: State, action: PayloadAction<AccountResponse>) {
      state.account = action.payload;
    },
    logout(state: State) {
      state.account = null;
      state.token = null;
    },
  },
});

export default authSlice;
