import store from '../store';  //contains access to authentication token

/*##################
Typography
##################*/
const getInitials = (name = '') => {
  return name.replace(/\s+/, ' ')
             .split(' ')
             .slice(0, 2)
             .map((v) => v && v[0].toUpperCase())
             .join('');
}

const numberWithPoints = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


/*##################
Time
###################*/
const addHours = (dt, hours, epoch=false) => {
  if (epoch)  {
    return dt.setHours(dt.getHours() + hours)
  }
  return new Date(dt.setHours(dt.getHours() + hours))

}

const addDays = (dt, days, epoch=false) => {
  let hours = days*24
  return addHours(dt, hours, epoch)
}

const getDateOnlyString = (dt, queryformat=false) => {
  if (queryformat) {
    return `${dt.getUTCFullYear()}-${dt.getUTCMonth() + 1}-${dt.getUTCDate()}`
  }
  return `${dt.getUTCDate()}-${dt.getUTCMonth() + 1}-${dt.getUTCFullYear()}`
}

const getTimeString = (dt, seconds=false, reset_time=false) => {
  if (seconds && reset_time) {
    return '00:00:00'
  }
  else if (!seconds && reset_time) {
    return '00:00'
  }
  else if (seconds) {
    return `${(dt.getUTCHours()<10?'0':'')}${dt.getUTCHours()}:${(dt.getMinutes()<10?'0':'')}${dt.getUTCMinutes()}:${dt.getUTCSeconds()}`
  }
  return `${(dt.getUTCHours()<10?'0':'')}${dt.getUTCHours()}:${(dt.getMinutes()<10?'0':'')}${dt.getUTCMinutes()}`
}

const getDateTimeString = (dt, queryformat=false, reset_time=false) => {
  return `${getDateOnlyString(dt, queryformat)} ${getTimeString(dt, queryformat, reset_time)}`
}


/*##################
HTTP REQUESTS
###################*/
const insertRequest = (url, data, callback, callbackOpts=undefined) => {
  let  json_request = {
    operation: "create_bulk",
    data: data,
  }
  executeRequest('POST', url, json_request, callback, callbackOpts)
}

const updateRequest = (url, data, updateFields, callback, callbackOpts=undefined) => {
  let  json_request = {
    operation: "update_bulk",
    data: data,
    update_fields: updateFields
  }
  executeRequest('PUT', url, json_request, callback, callbackOpts)
}

const deleteRequest = (url, data, filters, callback, callbackOpts=undefined) => {
  let  json_request = {operation: "delete_bulk"}
  if (filters) {
    json_request['filters'] = filters
  }
  else{
    json_request['data'] = data
  }

  executeRequest('DELETE', url, json_request, callback, callbackOpts)
}

const upsertRequest = (url, data, uniqueFields, callback, callbackOpts=undefined) => {
  let  json_request = {
    operation: "upsert_bulk",
    data: data,
    unique_fields: uniqueFields
  }

  executeRequest('PUT', url, json_request, callback, callbackOpts)

}

const executeRequest = (type, url, json_request, callback, callbackOpts) => {
  const { token } = store.getState().auth;

  let requestOptions = {
    method: type,
    headers: {
      'Authorization': 'Token ' + token,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(json_request)
  }

  fetch(url, requestOptions)
  .then((res) => res.json())
  .then((data) => {
    callback(data, false, callbackOpts)
  })
  .catch(() => {
    callback('something went wrong', true, callbackOpts)
  })
}


export {
  addHours,
  addDays,
  getInitials,
  getTimeString,
  getDateOnlyString,
  getDateTimeString,
  numberWithPoints,
  insertRequest,
  updateRequest,
  deleteRequest,
  upsertRequest,
}
