import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu';
import NavItem from './NavItem';

import store from '../../../store';


const greetingQuotes = ['Hello, sunshine!', 'Hey, howdy, hi!', 'Peek-a-boo!', 'Howdy-doody!', 'Waddup? Welcome!']

const items = [
  {
    href: '/app/menu',
    icon: RestaurantMenuIcon,
    title: 'Mijn Menu'
  },
  {
    href: '/app/recipes',
    icon: MenuBookIcon,
    title: 'Alle Recepten'
  },
  {
    href: '/app/shoppinglist',
    icon: ListAltIcon,
    title: 'Winkellijst'
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  name: {
    marginTop: 10
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const [userParams] = useState({user_name: store.getState().auth.account.username})

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={'/static/images/avatars/avatar_6.png'}
          to={"#" }   // setup/account
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h4"
        >
          {userParams.user_name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {greetingQuotes[Math.floor(Math.random() * greetingQuotes.length)]}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={() => onMobileClose()}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
