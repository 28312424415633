import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    //width: '15em',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  instruction: {
    textAlign: 'left',
    fontSize: 15,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  step: {
    textAlign: 'center',
    color: 'white',
    fontSize: 15,
    backgroundColor: 'rgba(50, 50, 50, 0.8)'
  },
  addButton: {
    width: '100%',
  }
}));

//const TotalCard = ({ className, ...rest }) => {
const InstructionCard = ({ step, picture, instruction, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={picture.source}
        title={`step ${step}`}
      >
        <Grid container justifyContent="flex-start" alignItems="flex-start">
          <Grid item xl={3} lg={3} sm={3} xs={3} >
            <Typography className={classes.step} variant="h4" component="h2">
              <b>{`step ${step}`}</b>
            </Typography>
          </Grid>
        </Grid>
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.instruction} variant="h5" component="h2">
          {instruction}
        </Typography>
      </CardContent>
    </Card>
  )
}


InstructionCard.propTypes = {
className: PropTypes.string
};

export default InstructionCard;
