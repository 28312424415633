import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const MoreMenu = ({menuData, className, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        className={className}
        edge="end"
        size="small"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'basic-button',}}
      >
        {menuData.map((item, i) => (
          <MenuItem key={`mu-${i}`} onClick={(event) => {handleClose(); item.handleFunction(item.functionParam);}}>{item.title}</MenuItem>
        ))}

      </Menu>
    </>
  );
};

MoreMenu.propTypes = {
  className: PropTypes.string
};

export default MoreMenu;
