
/****************************
Parses table data into a json with headers and rows
****************************/
const parseTable = (data, headCells) => {
  return {
    head_cells: headCells,
    rows: data
  }
}



export {
  parseTable,
}
