import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormGroup,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

import Page from '../../../../components/global/Page';
import RecipeCard from '../../../../components/cards/RecipeCard';

import 'swiper/swiper.min.css';

import {useFetchJson} from '../../../../utils/customHooks';
import {insertRequest, upsertRequest, deleteRequest} from '../../../../utils/general';
import {parseRecipeFilters, getRecipeFeatures, handleFilterChange, handleFiltersUpdate} from '../../../../utils/recipeFilters';
import store from '../../../../store';


/*******************
CONSTANTS & GLOBALS
*******************/
const recipeUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_RECIPES_SEARCH
const shoppingListSelectedUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_SHOPPING_LIST_SELECTED
const recipeFiltersUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_RECIPE_FILTERS
const menuUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_MENU
const imageUrl = process.env.REACT_APP_EP_MEDIA + 'images/recipes/'


/********************
GENERAL FUNCTIONS
********************/
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    // height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0)
  },
  recipeGrid: {
    backgroundColor: theme.palette.background.light,
    // paddingTop: theme.spacing(6),
    // paddingBottom: theme.spacing(8),
    //maxWidth: '68em', // four times the recipeCard width + buffer
  },
  generalInfo: {
    paddingBottom: theme.spacing(8),
  },
  swiperContainer: {
    width: 'auto',
  },
  swiperSlide: {
    width: 'auto',
  }
}));


/********************
COMPONENT
********************/
const RecipesView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [recipeParams] = useState({})
  const [userParams] = useState({user_id: store.getState().auth.account.id})
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [searchedRecipes, setSearchedRecipes] = useState([]);
  const [menu, setMenu] = useState([]);
  const [filters, setFilters] = useState([]);
  const [recipeSearch, setRecipeSearch] = useState('');
  const [loadedRecipes, setLoadedRecipes] = useState(20)  // load 20 recipes by default

  const [recipeData, loadingRecipeData, errorAPIRecipeData] = useFetchJson(recipeUrl, recipeParams)
  const [recipeFilters, loadingRecipeFilters, errorAPIRecipeFilters] = useFetchJson(recipeFiltersUrl, userParams)
  const [menuData, loadingMenuData, errorAPIMenuData] = useFetchJson(menuUrl, userParams)

  // parse fetched data
  useEffect(() => {
    if (!loadingRecipeData & !loadingRecipeFilters & !loadingMenuData) {
      let finalRecipeData = recipeData.map(recipe => {
        recipe['recipe_tags'] = recipe['recipe_tags'].map(t => t.tag)
        recipe['persons'] = 2
        recipe['imageUrl'] = imageUrl + recipe['image_name']
        return recipe
      })

      let finalFilters = parseRecipeFilters(recipeFilters)

      let finalMenuData = menuData.map(menuItem => {
        let recipe = menuItem.recipe
        recipe['persons'] = menuItem.persons
        recipe['imageUrl'] = imageUrl + recipe['image_name']
        return recipe
      })

      setRecipes(finalRecipeData)
      setFilters(finalFilters)
      setFilteredRecipes(finalRecipeData)
      setSearchedRecipes(finalRecipeData)
      setMenu(finalMenuData)
    }
  }, [loadingRecipeData, loadingRecipeFilters, loadingMenuData])

  // when the recipes change, apply again the filter result
  useEffect(() => {
    let result = handleFiltersUpdate(filters, recipes, userParams)

    setFilters(result.updatedFilters)
    setFilteredRecipes(result.filteredRecipes)
  }, [recipes])

  // when the filters change, apply again the search result
  useEffect(() => {
    handleRecipeSearch(recipeSearch)
  }, [filteredRecipes])

  //different butotn depending on whether already in menu
  const getRecipeButton = (recipeId) => {
    let button = {text: 'Toevoegen', handleFunction: handleAddRecipe}

    menu.forEach((recipe) => {
      if (recipe.id === recipeId) {
        button = {text: 'Reeds in het menu', disabled: true};
      }
    })

    return button
  }

  const requestResult = (res, error) => {
    //todo: implement error handling
    // console.log(res)
    // console.log(error)
  }

  //add recipe to my menu
  const handleAddRecipe = (recipeId) => {
    let menuCopy = [...menu]
    recipes.forEach((recipe) => {
      if (recipe.id === recipeId) {
        menuCopy.push(recipe)
        let newMenuItem = {
          persons: recipe.persons,
          recipe_id: recipe.id,
          user_id: userParams.user_id
        }
        insertRequest(menuUrl, [newMenuItem], requestResult)
      }
    })
    setMenu(menuCopy)
    deleteShoppingListSelected()
  }

  //view details of recipe
  const handleRecipeDetail = (recipeId) => {
    navigate(`/app/recipe?recipe_id=${recipeId}`)
  }

  //real-time recipe search handling
  const handleRecipeSearch = (searchVal) => {
    setRecipeSearch(searchVal)

    let searchResults = []
    filteredRecipes.forEach((recipe) => {
      if (recipe.title.toLowerCase().includes(searchVal.toLowerCase())) {
        searchResults.push(recipe)
      }
    })
    setSearchedRecipes(searchResults)
  }

  const handleFilterClick = (checked, filterName) => {
    let updatedFilters = handleFilterChange(checked, filterName, filters)
    let result = handleFiltersUpdate(updatedFilters, recipes, userParams)

    setFilters(result.updatedFilters)
    setFilteredRecipes(result.filteredRecipes)
  }

  //helper function to delete the shopping list selection from user
  const deleteShoppingListSelected = () => {
    let filters = {
      user_id: userParams.user_id
    }

    deleteRequest(shoppingListSelectedUrl, [],  filters, requestResult)
  }


  const loadMoreRecipes = () => {
    setLoadedRecipes(loadedRecipes + 20)
  }

  const getRecipeFilters = () => {
    return filters.map((filter, i) => {
      if (filter.name != 'Keto') {
        return (
          <Grid key={`gr-filter-${i}`} item xl={3} lg={3} md={4} sm={4} xs={4} style={{display: 'flex', justifyContent: 'center', alignItems : 'center'}}>
              <FormControlLabel
                label={filter.name}
                control={<Checkbox checked={filter.active} onChange={(e) => handleFilterClick(e.target.checked, filter.name)} />}
              />
          </Grid>
        )
      }
    })
  }

  const getRecipes = () => {
    const slicedRecipes = searchedRecipes.slice(0,loadedRecipes).map((recipe) => {
      return (
        <Grid key={`gr-${recipe.id}`} item xl={3} lg={3} md={4} sm={6} xs={12}>
          <RecipeCard
            key={`rc-${recipe.id}`}
            recipeId={recipe.id}
            picture={{source: recipe.imageUrl, handleFunction: handleRecipeDetail}}
            title={recipe.title}
            prepTime={`${recipe.prep_time} min`}
            button={getRecipeButton(recipe.id)}
          />
        </Grid>
      )
    })

    return slicedRecipes
  }

  return (
    <Page
      className={classes.root}
      title="Recepten"
    >
      <Container maxWidth="lg" className={classes.recipeGrid}>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Box>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon
                          fontSize="small"
                          color="action"
                        >
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  value={recipeSearch}
                  onChange={(e) => handleRecipeSearch(e.target.value)}
                  placeholder="Zoek op naam"
                  variant="outlined"
                />
              </Box>
              <Box style={{marginLeft: '5%', marginTop: '5%'}}>
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                  {filters.length > 0 ?
                    getRecipeFilters()
                  :
                  <div></div>
                  }
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <Container>
        <Box my={1}>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {searchedRecipes.length > 0  ?
              getRecipes()
              :
              <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                <Typography variant="h3" component="h1">
                  <b>No results found</b>
                </Typography>
              </Grid>
            }
          </Grid>
          {(searchedRecipes.length > 0 && loadedRecipes < searchedRecipes.length)?
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid key={'Load More'} item xl={3} lg={3} md={4} sm={6} xs={12}>
              <Button
                color="primary"
                // disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={loadMoreRecipes}
              >
                Load More
              </Button>
            </Grid>
          </Grid>
          :
          <div></div>
          }
        </Box>
      </Container>
    </Page>
  );
}

export default RecipesView;
