import {upsertRequest} from './general';

/*******************
CONSTANTS & GLOBALS
*******************/
const recipeFiltersUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_RECIPE_FILTERS

const filtersDefault = [
  {
    name: 'Veggie',
    active: false
  },
  {
    name: 'Keto',
    active: false
  },
  {
    name: 'Snel',
    active: false
  }
]


/*******************
Functions
*******************/
const parseRecipeFilters = (recipeFilters) => {
  let finalFilters = []
  filtersDefault.forEach((f) => {
    if (recipeFilters.recipe_filters.includes(f.name)) {
      finalFilters.push({
        name: f.name,
        active: true
      })
    }
    else {
      finalFilters.push(f)
    }
  })

  return finalFilters

}


const getRecipeFeatures = (recipe) => {
  let features = []
  if(recipe.recipe_tags.includes('Veggie') || recipe.recipe_tags.includes('Vegetarian')) {
    features.push('Veggie')
  }
  if(recipe.recipe_tags.includes('Keto') || recipe.recipe_tags.includes('Low Carb')) {
    features.push('Keto')
  }
  if(recipe.prep_time <= 30) {
    features.push('Snel')
  }

  return features
}


//real-time recipe search handling
const handleFilterChange = (checked, filterName, filters) => {
  let updatedFilters = filters.map(filter => {
    if (filter.name == filterName) {
      filter.active = checked
    }
    return filter
  })

  return updatedFilters

  // handleFiltersUpdate(updatedFilters)
}


//real-time filter handling
const handleFiltersUpdate = (updatedFilters, recipes, userParams) => {
  let activeFilters = []
  updatedFilters.map((filter) => {
    if (filter.active) {
      activeFilters.push(filter.name)
    }
  })

  let filteredResults = []
  recipes.forEach((recipe) => {
    let recipeFeatures = getRecipeFeatures(recipe)

    // if active filters is empty, all recipes will be pushed (special functionality of every function)
    if (activeFilters.every(f => recipeFeatures.includes(f))) {
      filteredResults.push(recipe)
    }
  })

  // update user filters in database
  let recordRecipeFilters = {
    user_id: userParams.user_id,
    recipe_filters: "['" + activeFilters.join("','") + "']"  // needs to be {0, 1, 5} for postgres
  }

  //upsert filters to the database
  upsertRequest(recipeFiltersUrl, [recordRecipeFilters], ['user_id'], requestResult)

  return {
    updatedFilters: updatedFilters,
    filteredRecipes: filteredResults
  }
  // setFilters(updatedFilters)
  // setFilteredRecipes(filteredResults)
}


const requestResult = (res, error) => {
  //todo: implement error handling
  // console.log(res)
  // console.log(error)
}

export {
  parseRecipeFilters,
  getRecipeFeatures,
  handleFilterChange,
  handleFiltersUpdate
}
