import React from 'react';
import { Navigate } from 'react-router-dom';
//containers
import AppContainer from './containers/AppContainer';
import MainContainer from './containers/MainContainer';
import SetupContainer from './containers/SetupContainer';
//auth
import LoginView from './views/auth/LoginView';
import LogoutView from './views/auth/LogoutView';
import RegisterView from './views/auth/RegisterView';
//app
import MyMenuView from './views/app/menu/MyMenuView';
import RecipesView from './views/app/menu/RecipesView';
import RecipeView from './views/app/menu/RecipeView';
import ShoppingListView from './views/app/shopping/ShoppingListView';
import NotificationsView from './views/app/notifications/NotificationsView';
import FePotential from './views/app/fepotential/FePotential';
//setup
import AccountView from './views/setup/account/AccountView';
import UserSettingsView from './views/setup/users/UserSettingsView';
import NotifcationSettingsView from './views/setup/notification_settings/NotificationSettingsView';
//errors
import NotFoundView from './views/errors/NotFoundView';



const routes = (isLoggedIn) => [
  {
    path: '/',
    element: <MainContainer />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'logout', element: <LogoutView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/menu" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'app',
    element: isLoggedIn ? <AppContainer /> : <Navigate to="/login" />,
    children: [
      { path: 'menu', element: <MyMenuView /> },
      { path: 'recipe', element: <RecipeView /> },
      { path: 'recipes', element: <RecipesView /> },
      { path: 'shoppinglist', element: <ShoppingListView /> },
      { path: 'notifications', element: <NotificationsView /> },
      { path: 'fepotential', element: <FePotential /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'setup',
    element: isLoggedIn ? <SetupContainer /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'notifications', element: <NotifcationSettingsView /> },
      { path: 'users', element: <UserSettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
