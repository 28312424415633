import React, { useState, useEffect } from 'react';

import DataFrameTable from '../../components/tables/DataFrameTable';

import {parseTable} from '../../utils/graphsParsing';



const DataFrameTableStateful = (props) => {
  //linecharts
  const [tableData, setTableData] = useState()

  // parse submissions fetched data
  useEffect(() => {
    if(!props.loadingData) {
      let parsedTable = parseTable(props.data, props.headCells)
      setTableData(parsedTable)
    }
  }, [props])

  return (
    tableData ?
    <DataFrameTable
      title={props.title}
      data={tableData}
      selected={props.selected}
      handleClick={props.handleClick}
      orderByPrimary={props.orderByPrimary}
      orderBySecondary={props.orderBySecondary}
    />
    : "Loading..."
  );
};

export default DataFrameTableStateful;
