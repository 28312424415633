import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  Paper,
  makeStyles
} from '@material-ui/core';

function descendingComparator(a, b, orderBy, orderBySec) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  if (b[orderBySec] < a[orderBySec]) {
    return -1;
  }
  if (b[orderBySec] > a[orderBySec]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy, orderBySec) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, orderBySec)
    : (a, b) => -descendingComparator(a, b, orderBy, orderBySec);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead({headCells, ...rest}) {
  const { classes, order, orderBy, rowCount, onRequestSort } = rest;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={headCell.hidden ? classes.headerCellHidden : classes.headerCell}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: '100%',
  },
  headerCell: {
    fontWeight: 600,
    fontSize: '0.8em',
    padding: '5px 5px 5px 5px'
  },
  headerCellHidden: {
    fontWeight: 600,
    fontSize: '0.8em',
    padding: '5px 5px 5px 5px',
    display: 'none'
  },
  tableRow: {
    cursor: 'pointer',
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: '#d4e157',
    }
  },
  tableCell: {
    fontSize: '0.7em',
    padding: '5px 5px 5px 5px'
  },
  tableCellHidden: {
    fontSize: '0.7em',
    padding: '5px 5px 5px 5px',
    display: 'none'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


const DataFrameTable = ({ title, data, selected, handleClick, orderByPrimary, orderBySecondary, ...rest }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(orderByPrimary);
  const [orderBySec, setOrderBySec] = React.useState(orderBySecondary);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(200);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = 0 //rowsPerPage - Math.min(rowsPerPage, data.rows.length - page * rowsPerPage);

  // const tableCols = data.head_cells.map((col) => { return col.id})

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          color="textPrimary"
          variant="h3"
          align="center"
        >
          {title}
        </Typography>
        <Paper className={classes.paper}>
          <TableContainer style={{overflowX: 'hidden'}}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                headCells={data.head_cells}
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={data.rows.length}
              />
              <TableBody>
                {stableSort(data.rows, getComparator(order, orderBy, orderBySec))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        className={classes.tableRow}
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        tabIndex={-1}
                        key={`tr-${row.id}`}
                        selected={selected.includes(row.id)}
                      >
                        {data.head_cells.map((col, ind) => {    //{tableCols.map((col, ind) => {
                          // console.log(col.hidden)
                          // col.hidden ? console.log('hidden') : console.log('visible')
                          return(
                            (col.id == 'checked') ?
                              <TableCell
                                padding="checkbox"
                                key={col.id + ind}
                                style={{width: '12px', padding: '0px'}}>
                                  <Checkbox
                                    checked={row.checked}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                  />
                              </TableCell>
                            :
                              <TableCell
                                className={col.hidden? classes.tableCellHidden : classes.tableCell}
                                key={col.id + ind}
                                align="left">
                                {row[col.id]}
                              </TableCell>
                          )
                        })}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 33*emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Rows:"
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
            component="div"
            count={data.rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </CardContent>
    </Card>
  );
}

export default DataFrameTable
