import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    notification: {
      text: 'Tweets have been 20% more positive last week',
      subject: 'Biocartis',
      category: 'Twitter',
      type: 'Note'
    },
    createdAt: 1555016400000,
  },
  {
    id: uuid(),
    notification: {
      text: 'There has been a new reddit post with more than 50 comments',
      subject: 'Novadip Biosciences',
      category: 'Reddit',
      type: 'Alarm'
    },
    createdAt: 1555016400000,
  },
  {
    id: uuid(),
    notification: {
      text: 'Elon Musk has posted a new tweet about Neuralink',
      subject: 'Neuralink',
      category: 'Twitter',
      type: 'Alarm'
    },
    createdAt: 1555016400000,
  },
  {
    id: uuid(),
    notification: {
      text: 'Number of searches on Google have increased by 34% in one day',
      subject: 'Oxurion',
      category: 'Analytics',
      type: 'Alarm'
    },
    createdAt: 1554930000000,
  },
  {
    id: uuid(),
    notification: {
      text: 'Stock price has decreased with 5% in 24h',
      subject: 'Novo Nordisk',
      category: 'Finance',
      type: 'Alarm'
    },
    createdAt: 1554757200000,
  },
  {
    id: uuid(),
    notification: {
      text: 'Number of tweets have decreased with 50% over the last 6 months',
      subject: 'eTheRNA immunotherapies',
      category: 'Twitter',
      type: 'Note'
    },
    createdAt: 1554670800000,
  },
  {
    id: uuid(),
    notification: {
      text: 'Tweets have been 20% more negative last week',
      subject: 'Vertex Pharmaceuticals',
      category: 'Twitter',
      type: 'Note'
    },
    createdAt: 1554325200000,
  },
  {
    id: uuid(),
    notification: {
      text: 'Article published in the New York Times',
      subject: 'Biocartis',
      category: 'News',
      type: 'Alarm'
    },
    createdAt: 1523048400000,
  },
  {
    id: uuid(),
    notification: {
      text: 'Boosting Algorithm suggests to invest in Novo Nordisk',
      subject: 'Novo Nordisk',
      category: 'Analytics',
      type: 'Alarm'
    },
    createdAt: 1554702800000,
  },
  {
    id: uuid(),
    notification: {
      text: 'Number of news articles have increased 200% in the past 12 months',
      subject: 'Novadip Biosciences',
      category: 'News',
      type: 'Note'
    },
    createdAt: 1522702800000,
  }
];
