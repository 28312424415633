import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  card: {
    height: '100%',
    //width: '15em',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    cursor: 'pointer',
  },
  cardContent: {
    flexGrow: 1,
  },
  recipeTitle: {
    textAlign: 'left',
    fontSize: 15,
    height: '2.5em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    display: '-webkit-box',
  },
  prepTime: {
    textAlign: 'center',
    color: 'white',
    fontSize: 13,
    backgroundColor: 'rgba(50, 50, 50, 0.8)',
  },
  addButton: {
    width: '100%',
  }
}));

//const TotalCard = ({ className, ...rest }) => {
const RecipeCard = ({ recipeId, picture, title, prepTime, button={}, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image={picture.source}
        title={title}
        onClick={() => picture.handleFunction(recipeId)}
      >
        <Grid container justifyContent="flex-end" alignItems="flex-end">
          <Grid item xl={3} lg={3} sm={3} xs={3} >
            <Typography className={classes.prepTime} variant="h5" component="h2">
              <b>{prepTime}</b>
            </Typography>
          </Grid>
        </Grid>
      </CardMedia>
      <CardContent className={classes.cardContent}>
        <Typography className={classes.recipeTitle} variant="h5" component="h2">
          <b>{title}</b>
        </Typography>
        {/*
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xl={12} lg={12} sm={12} xs={12} >

          </Grid>
        </Grid>
        */}
      </CardContent>
      <CardActions>
        <Grid container justifyContent="center" alignItems="center">
          <Button
            id={button.id}
            className={classes.addButton}
            size="small"
            color="secondary"
            variant="contained"
            disabled={button.disabled}
            onClick={() => button.handleFunction(recipeId)}
          >
            {button.text}
          </Button>
        </Grid>
      </CardActions>
    </Card>
  )
}


RecipeCard.propTypes = {
className: PropTypes.string
};

export default RecipeCard;
