import React, { useState, useEffect } from 'react';
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination } from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import Page from '../../../../components/global/Page';
import DataFrameTableStateful from '../../../../components_stateful/tables/DataFrameTableStateful';
import ManuelIngredientsList from '../../../../components/lists/ManuelIngredientsList';



import {useFetchJson} from '../../../../utils/customHooks';
import {upsertRequest, deleteRequest} from '../../../../utils/general';
import store from '../../../../store';


/*******************
CONSTANTS & GLOBALS
*******************/
const shoppingListUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_SHOPPING_LIST
const shoppingListSelectedUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_SHOPPING_LIST_SELECTED
const shoppingListCustomUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_SHOPPING_LIST_CUSTOM

const shoppingListHeadCells = [
  { id: 'category', numeric: false, disablePadding: false, label: 'Category' },
  { id: 'ingredient', numeric: false, disablePadding: false, label: 'Ingredient' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
]


/********************
GENERAL FUNCTIONS
********************/
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    // height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0)
  },
  swiperSlide: {
    minHeight: '85vh'
  },
  swipeInstruction: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',

  }
}));


/********************
COMPONENT
********************/
const ShoppingListView = () => {
  const classes = useStyles();
  const [userParams] = useState({user_id: store.getState().auth.account.id})
  const [refetch, setRefetch] = useState(0)
  const [shoppingList, setShoppingList] = useState([])
  const [manuelList, setManuelList] = useState([])
  const [rowsSelected, setRowsSelected] = useState([])

  const [shoppingListTableData, loadingShoppingListTableData, errorShoppingListTableData] = useFetchJson(shoppingListUrl, userParams, refetch)
  const [manuelListTableData, loadingManuelListTableData, errorManuelListTableData] = useFetchJson(shoppingListCustomUrl, userParams, refetch)
  const [shoppingListSelectedData, loadingShoppingListSelectedData, errorShoppingListSelectedData] = useFetchJson(shoppingListSelectedUrl, userParams, refetch)


  // Three different parse functions required due to the refetching possibilities
  useEffect(() => {
    if (!loadingShoppingListTableData) {
      let finalShoppingList = shoppingListTableData.map((item, i) => {
        return {
          id: i,
          category: item.category,
          ingredient: item.ingredient,
          amount: `${item.amount} ${item.unit}`
        }
      })

      setShoppingList(finalShoppingList)
    }
  }, [loadingShoppingListTableData])

  useEffect(() => {
    if (!loadingShoppingListSelectedData) {
      if (shoppingListSelectedData) {
        setRowsSelected(shoppingListSelectedData.selected_rows)
      }
      else {
        setRowsSelected([])  // required in case of refetch
      }
    }
  }, [loadingShoppingListSelectedData])

  useEffect(() => {
    if (!loadingManuelListTableData) {
      let finalManuelList = manuelListTableData.map((item, i) => {
        return {
          id: item.id,
          checked: item.checked,
          category: item.category,
          ingredient: item.ingredient,
          amount: `${item.amount} ${item.unit}`
        }
      })

      setManuelList(finalManuelList)
    }
  }, [loadingManuelListTableData])


  const requestResult = (res, error) => {
    //todo: implement error handling
    // console.log(res)
    // console.log(error)
  }

  const manuelListRequestResult = (res, error) => {
    //todo: implement error handling

    //refetch the shopping list
    setRefetch(refetch + 1)
  }


  const handleTableRowClick = (event, row) => {
    let selected = [...rowsSelected]
    if (selected.includes(row.id)) {
      const i = selected.indexOf(row.id)
      selected.splice(i, 1)
    }
    else {
      selected.push(row.id)
    }

    let recordShoppingListSelected = {
      user_id: userParams.user_id,
      selected_rows: `[${selected.toString()}]`  // needs to be {0, 1, 5} for postgres
    }

    upsertRequest(shoppingListSelectedUrl, [recordShoppingListSelected], ['user_id'], requestResult)  //upsert to avoid the need for primary key (should be improved)
    setRowsSelected(selected)
  };


  //remove ingredient from manuel list
  const handleRemove = (ingredient) => {
    let filters = {
      id: ingredient.id,
      user_id: userParams.user_id
    }

    deleteRequest(shoppingListCustomUrl, [], filters, manuelListRequestResult)
    deleteShoppingListSelected()
  }


  //upsert ingredient to manuel list
  const handleUpsert = (ingredient) => {
    ingredient['user_id'] = userParams.user_id

    if (ingredient['id'] == -1) {
      delete ingredient['id']
      upsertRequest(shoppingListCustomUrl, [ingredient], ['user_id', 'ingredient'], manuelListRequestResult)  // new item
    }
    else {
      upsertRequest(shoppingListCustomUrl, [ingredient], ['id', 'user_id'], manuelListRequestResult)  // updated item
    }

    deleteShoppingListSelected()
  }


  // clear the full manuel list
  const handleClear = () => {
    let filters = {
      user_id: userParams.user_id
    }

    deleteRequest(shoppingListCustomUrl, [], filters, manuelListRequestResult)
    deleteShoppingListSelected()
    setManuelList([])
  }


  //helper function to delete the shopping list selection from user
  const deleteShoppingListSelected = () => {
    let filters = {
      user_id: userParams.user_id
    }

    deleteRequest(shoppingListSelectedUrl, [],  filters, requestResult)
  }


  return (
    <Page
      className={classes.root}
      title="Shopping List"
    >
      <Container maxWidth={false}>
        <Box my={1}>
          <Grid container item xl={12} lg={12} sm={12} xs={12} justifyContent="center" alignItems="center">
            <Swiper
              className={classes.swiperContainer}
              spaceBetween={10}
              slidesPerView={1}
              modules={[Pagination]}
              pagination={{ clickable: true }}
              // onSlideChange={() => console.log('slide change')}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide key={`slide-shoppinglist`} className={classes.swiperSlide}>
                <Typography variant="h5" component="h5" className={classes.swipeInstruction}>
                  <i>Swipe links om manueel items toe te voegen</i>
                </Typography>
                <DataFrameTableStateful
                  title="Winkellijst"
                  loadingData={false}
                  headCells={shoppingListHeadCells}
                  data={shoppingList.length > 0 ? shoppingList : [{category: 'No recipes found in your menu'}]}
                  selected={rowsSelected}
                  handleClick={handleTableRowClick}
                  orderByPrimary='category'
                  orderBySecondary='ingredient'
                />
              </SwiperSlide>
              <SwiperSlide key={`slide-shoppinglist2`} className={classes.swiperSlide}>
                <Typography variant="h5" component="h5" className={classes.swipeInstruction}>
                  <i>Swipe rechts om terug naar winkellijstje te gaan</i>
                </Typography>
                <ManuelIngredientsList
                  ingredients={manuelList}
                  handleUpsert={handleUpsert}
                  handleRemove={handleRemove}
                  handleClear={handleClear}
                />
              </SwiperSlide>
            </Swiper>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}

export default ShoppingListView;
