import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import Page from '../../../../components/global/Page';
import RecipeCard from '../../../../components/cards/RecipeCard';
import RecipeList from '../../../../components/lists/RecipeList';

import 'swiper/swiper.min.css';

import {useFetchJson} from '../../../../utils/customHooks';
import {insertRequest, upsertRequest, deleteRequest} from '../../../../utils/general';
import {parseRecipeFilters, getRecipeFeatures, handleFilterChange, handleFiltersUpdate} from '../../../../utils/recipeFilters';
import store from '../../../../store';


/*******************
CONSTANTS & GLOBALS
*******************/
const recipeUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_RECIPES
const chefsChoiceUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_CHEFS_CHOICE
const menuUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_MENU
const shoppingListSelectedUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_SHOPPING_LIST_SELECTED
const imageUrl = process.env.REACT_APP_EP_MEDIA + 'images/recipes/'
const recipeFiltersUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_RECIPE_FILTERS

// const filtersDefault = [
//   {
//     name: 'Veggie',
//     active: false
//   }
// ]


/********************
GENERAL FUNCTIONS
********************/
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    // height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0)
  },
  cardGrid: {
    backgroundColor: theme.palette.background.light,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(8),
  },
  generalInfo: {
    paddingBottom: theme.spacing(8),
  },
  swiperContainer: {
    width: 'auto',
  },
  swiperSlide: {
    width: '15em',
  }
}));


/********************
COMPONENT
********************/
const MyMenuView = () => {
  const classes = useStyles();
  const navigate = useNavigate()
  const [emptyParams] = useState({})
  const [userParams] = useState({user_id: store.getState().auth.account.id})
  const [chefsChoice, setChefsChoice] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [menu, setMenu] = useState([]);
  const [filters, setFilters] = useState([]);

  const [chefsChoiceData, loadingChefsChoiceData, errorAPIChefsChoiceData] = useFetchJson(chefsChoiceUrl, emptyParams)
  const [recipeFilters, loadingRecipeFilters, errorAPIRecipeFilters] = useFetchJson(recipeFiltersUrl, userParams)
  const [menuData, loadingMenuData, errorAPIMenuData] = useFetchJson(menuUrl, userParams)


  // parse fetched data
  useEffect(() => {
    if (!loadingChefsChoiceData & !loadingMenuData & !loadingRecipeFilters) {
      let finalChefsChoice = chefsChoiceData.map(chefsChoice => {
        let recipe = chefsChoice.recipe
        recipe['recipe_tags'] = recipe['recipe_tags'].map(t => t.tag)
        recipe['persons'] = 2
        recipe['imageUrl'] = imageUrl + recipe['image_name']
        return recipe
      })

      let finalFilters = parseRecipeFilters(recipeFilters)

      let finalMenuData = menuData.map(menuItem => {
        let recipe = menuItem.recipe
        recipe['persons'] = menuItem.persons
        recipe['imageUrl'] = imageUrl + recipe['image_name']
        return recipe
      })

      setFilters(finalFilters)
      setChefsChoice(finalChefsChoice)
      setFilteredRecipes(finalChefsChoice)
      setMenu(finalMenuData)
    }
  }, [loadingChefsChoiceData, loadingMenuData])

  // when the recipes change, apply again the filter result
  useEffect(() => {
    let result = handleFiltersUpdate(filters, chefsChoice, userParams)

    setFilters(result.updatedFilters)
    setFilteredRecipes(result.filteredRecipes)
  }, [chefsChoice])

  //different button depending on whether already in menu
  const getRecipeButton = (recipeId) => {
    let button = {text: 'Toevoegen', handleFunction: handleAddRecipe}

    menu.forEach((recipe) => {
      if (recipe.id === recipeId) {
        button = {text: 'Reeds in het menu', disabled: true};
      }
    })

    return button
  }

  const requestResult = (res, error) => {
    //todo: implement error handling
    // console.log(res)
    // console.log(error)
  }

  //add recipe to my menu
  const handleAddRecipe = (recipeId) => {
    let menuCopy = [...menu]
    chefsChoice.forEach((recipe) => {
      if (recipe.id === recipeId) {
        menuCopy.push(recipe)
        let newMenuItem = {
          persons: recipe.persons,
          recipe_id: recipe.id,
          user_id: userParams.user_id
        }
        insertRequest(menuUrl, [newMenuItem], requestResult)
      }
    })

    deleteShoppingListSelected()
    setMenu(menuCopy)
  }

  //remove recipe from my menu
  const handleRemoveRecipe = (recipeId) => {
    let menuCopy = menu.filter(recipe => recipe.id !== recipeId)
    let filters = {
      recipe_id: recipeId,
      user_id: userParams.user_id
    }

    deleteShoppingListSelected()
    deleteRequest(menuUrl, [], filters, requestResult)
    setMenu(menuCopy)
  }

  //helper function to delete the shopping list selection from user
  const deleteShoppingListSelected = () => {
    let filters = {
      user_id: userParams.user_id
    }

    deleteRequest(shoppingListSelectedUrl, [],  filters, requestResult)
  }

  //change the amount of persons the menu recipe is for
  const handlePersonsChange = (recipeId, persons) => {
    let menuCopy = [...menu]
    menuCopy = menuCopy.map((recipe) => {
      if (recipe.id === recipeId) {
        recipe.persons = persons
        let updateMenuItem = {
          persons: recipe.persons,
          recipe_id: recipe.id,
          user_id: userParams.user_id
        }
        upsertRequest(menuUrl, [updateMenuItem], ['user_id', 'recipe_id'], requestResult)  //upsert to avoid the need for primary key (should be improved)
      }
      return recipe
    })
    deleteShoppingListSelected()
    setMenu(menuCopy)
  }

  // clear the full menu
  const handleClear = () => {
    let filters = {
      user_id: userParams.user_id
    }
    deleteRequest(menuUrl, [], filters, requestResult)
    deleteShoppingListSelected()
    setMenu([])
  }

  //view details of recipe
  const handleRecipeDetail = (recipeId) => {
    navigate(`/app/recipe?recipe_id=${recipeId}`)
  }

  //real-time recipe search handling
  const handleFilterClick = (checked, filterName) => {
    let updatedFilters = handleFilterChange(checked, filterName, filters)
    let result = handleFiltersUpdate(updatedFilters, chefsChoice, userParams)

    setFilters(result.updatedFilters)
    setFilteredRecipes(result.filteredRecipes)
  }

  const getChefsChoiceFilters = () => {
    return filters.map((filter, i) => {
      if (filter.name != 'Keto') {
        return (
          <Grid key={`gr-filter-${i}`} item xl={3} lg={3} md={4} sm={4} xs={4} style={{display: 'flex', justifyContent: 'center', alignItems : 'center'}}>
              <FormControlLabel
                label={filter.name}
                control={<Checkbox checked={filter.active} onChange={(e) => handleFilterClick(e.target.checked, filter.name)} />}
              />
          </Grid>
        )
      }
    })
  }

  return (
    <Page
      className={classes.root}
      title="Mijn Menu"
    >
      <Container maxWidth={false}>
        <Box my={6}>
          <Grid container direction="column" spacing={3} justifyContent="center" alignItems="center">
            <Grid item xl={12} lg={12} sm={12} xs={12} >
              <Typography variant="h3" component="h1">
                <b>Mijn Menu</b>
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12} >
              <RecipeList
                recipes={menu}
                handleRecipeDetail={handleRecipeDetail}
                handlePersonsChange={handlePersonsChange}
                handleRemoveRecipe={handleRemoveRecipe}
                handleClear={handleClear}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box my={6}>
          {// <Grid container direction="column" spacing={3} justifyContent="center" alignItems="center">
          }
          <Grid container  spacing={3} justifyContent="center" alignItems="center">
            <Grid item xl={12} lg={12} sm={12} xs={12} style={{textAlign: "center"}} >
              <Typography variant="h3" component="h1">
                <b>Keuze van de chef</b>
              </Typography>
            </Grid>
            {filters.length > 0 ?
              getChefsChoiceFilters()
            :
            <div></div>
            }
            <Grid item xl={12} lg={12} sm={12} xs={12} >
              <Swiper
                className={classes.swiperContainer}
                spaceBetween={10}
                slidesPerView='auto'
                // onSlideChange={() => console.log('slide change')}
                // onSwiper={(swiper) => console.log(swiper)}
              >
                {filteredRecipes.length > 0 ?
                  filteredRecipes.map((recipe, i) => (
                    <SwiperSlide key={`slide-${recipe.id}`} className={classes.swiperSlide}>
                        <RecipeCard
                          key={`rc-${recipe.id}`}
                          recipeId={recipe.id}
                          picture={{source: recipe.imageUrl, handleFunction: handleRecipeDetail}}
                          title={recipe.title}
                          prepTime={`${recipe.prep_time} min`}
                          button={getRecipeButton(recipe.id)}
                        />
                    </SwiperSlide>
                  ))
                  :
                  'loading...'
                }
              </Swiper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}

export default MyMenuView;
