import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import {
  makeStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container,
  Divider,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  MenuItem,
  Typography,
  Select,
} from '@material-ui/core';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import 'swiper/swiper.min.css';
// import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';

import Page from '../../../../components/global/Page';
import InstructionCard from '../../../../components/cards/InstructionCard';
import {useFetchJson} from '../../../../utils/customHooks';
import {insertRequest} from '../../../../utils/general';
import store from '../../../../store';


/*******************
CONSTANTS & GLOBALS
*******************/
const recipeUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_RECIPES
const ingredientsUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_INGREDIENTS
const ingredientsExtraUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_INGREDIENTS_EXTRA
const menuUrl = process.env.REACT_APP_BASE_API_URL + process.env.REACT_APP_EP_MENU
const imageUrl = process.env.REACT_APP_EP_MEDIA + 'images/recipes/'

const ingredientSortOrder = ['smaakmakers','groenten','koolhydraten','fruit','zuivel','vleesvervanger', 'vlees', 'vis', 'noten','conserven','sauzen','kruiden']
const nutritionTranslation = {
  energy_kj: 'Energie (KJ)',
  energy_kcal: 'Energie (kcal)',
  fats: 'Vetten',
  sat_fats: 'waarvan verzadigd',
  carbs: 'Koolhydraten',
  sug_carbs: 'waarvan suikers',
  fibres: 'Vezels',
  protein: 'Eiwitten',
  salt: 'Zout',
}


/********************
GENERAL FUNCTIONS
********************/
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    // height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(0),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  roundedGrid: {
    backgroundColor: 'white',
    borderRadius: '5px',
    marginTop: '0.5em'
  },
  swiperContainer: {
    width: 'auto',
  },
  swiperSlide: {
    maxWidth: '20em',
  },
  amount: {
    textAlign: "right",
  },
  personsForm: {
    width: '8em',
    marginRight: '2em',
  },
  addButton: {
    width: '100%',
    marginTop: '1.3em'
  },
  rowText: {
    //fontSize: '0.8em',
    fontSize: 15,
  },
  prepTime: {
    textAlign: 'center',
    color: 'white',
    fontSize: 20,
    backgroundColor: 'rgba(50, 50, 50, 0.8)',
    paddingBottom: 5
  },
}));


/********************
COMPONENT
********************/
const RecipeView = () => {
  const classes = useStyles();
  const [userParams] = useState({user_id: store.getState().auth.account.id})
  const [searchParams] = useSearchParams();
  const [recipeParams] = useState({id: searchParams.get("recipe_id")})
  const [ingredientsParams] = useState({recipe_id: searchParams.get("recipe_id")})
  const [recipe, setRecipe] = useState([]);
  const [persons, setPersons] = useState(2);
  const [instructions, setInstructions] = useState([]);
  const [nutrition, setNutrition] = useState([]);
  const [ingredients, setIngredients] = useState({});
  const [menu, setMenu] = useState([]);
  const [disableAdd, setDisableAdd] = useState(false);

  const [recipeData, loadingRecipeData, errorAPIRecipeData] = useFetchJson(recipeUrl, recipeParams)
  const [ingredientsData, loadingIngredientsData, errorAPIIngredientsData] = useFetchJson(ingredientsUrl, ingredientsParams)
  const [ingredientsExtraData, loadingIngredientsExtraData, errorAPIIngredientsExtraData] = useFetchJson(ingredientsExtraUrl, ingredientsParams)
  const [menuData, loadingMenuData, errorAPIMenuData] = useFetchJson(menuUrl, userParams)

  // parse fetched data
  useEffect(() => {
    if (!loadingRecipeData & !loadingIngredientsData & !loadingIngredientsExtraData & !loadingMenuData) {
      let recipePersons = persons
      let parsedRecipeData = recipeData[0]
      parsedRecipeData['image_url'] = imageUrl + parsedRecipeData['image_name']

      menuData.forEach(menuItem => {
        if (menuItem.recipe.id === recipeParams.id) {
          recipePersons = menuItem.persons
          setPersons(menuItem.persons)
          setDisableAdd(true)
        }
      })

      let parsedIngredientsData = {'smaakmakers': []}
      ingredientsData.forEach(ingr => {
        if (!(ingr.category in parsedIngredientsData)) {
          parsedIngredientsData[ingr.category] = []
        }
        ingr.calc_amount = Math.round(ingr.amount * recipePersons / 2 * 100) / 100
        parsedIngredientsData[ingr.category].push(ingr)
      })
      ingredientsExtraData.forEach(ingr_extra => {
        ingr_extra.calc_amount = ingr_extra.amount ? Math.round(ingr_extra.amount * recipePersons / 2 * 100) / 100 : undefined
        parsedIngredientsData['smaakmakers'].push(ingr_extra)
      })

      let parsedInstructions = JSON.parse(parsedRecipeData.instructions)
      parsedInstructions = parsedInstructions.map(instr => {
        instr['step'] = instr['step'] + 1
        instr['image_url'] = imageUrl + instr['image_name']
        return instr
      })

      let parsedNutrition = JSON.parse(parsedRecipeData.nutrition)

      setRecipe(parsedRecipeData)
      setInstructions(parsedInstructions)
      setNutrition(parsedNutrition)
      setIngredients(parsedIngredientsData)
    }
  }, [loadingRecipeData, loadingIngredientsData, loadingIngredientsExtraData, loadingMenuData])

  //change the amount of persons the recipe is for
  const handlePersonsChange = (persons) => {
    let ingredientsCopy = {...ingredients}
    for (let category in ingredientsCopy) {
      ingredientsCopy[category] = ingredientsCopy[category].map(ingr => {
        ingr.calc_amount = ingr.amount ? Math.round(ingr.amount * persons / 2 * 100) / 100 : undefined

        return ingr
      })
    }
    setPersons(persons)
    setIngredients(ingredientsCopy)
  }

  //add recipe to my menu
  const handleAddRecipe = () => {
    let menuCopy = [...menu]
    let newMenuItem = {
      persons: persons,
      recipe_id: recipeParams.id,
      user_id: userParams.user_id
    }
    menuCopy.push(recipe)
    insertRequest(menuUrl, [newMenuItem], requestResult)
    setMenu(menuCopy)
    setDisableAdd(true)
  }


  const requestResult = (res, error) => {
    //todo: implement error handling
    // console.log(res)
    // console.log(error)
  }


  const getIngredients = (category) => {
    if (category in ingredients) {
      return (
        <Grid key={`cat-${category}`} item xl={6} lg={6} sm={6} xs={12} style={{paddding: '5em'}}>
          <Typography variant="h4" component="h4" align="left" style={{marginBottom: '0.5em'}}>
            {category}
          </Typography>
          {ingredients[category].map((ingr, i) => {
            return (
              <Grid key={`ingr-${i}`} container spacing={1} justifyContent="flex-start" alignItems="center">
                <Grid item xl={8} lg={8} sm={8} xs={8}>
                  <Typography className={classes.rowText} variant="h5" component="p" >
                    {ingr.ingredient}
                  </Typography>

                </Grid>
                <Grid item xl={4} lg={4} sm={4} xs={4} className={classes.amount}>
                  <Typography className={classes.rowText} variant="h5" component="p">
                    <b>{ingr.calc_amount} {ingr.unit}</b>
                  </Typography>
                </Grid>
                <Grid item xl={12} lg={12} sm={12} xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      )
    }
    return null
  }

  const getNutrition = () => {
    if (Object.keys(nutrition).length > 0) {
      let rows = Object.keys(nutrition).map((key) => {
        return (
          <>
            <Grid item xl={8} lg={8} sm={8} xs={8}>
              <Typography className={classes.rowText} variant="h5" component="p">
                <b>{nutritionTranslation[key]}</b>
              </Typography>
            </Grid>
            <Grid item xl={4} lg={4} sm={4} xs={4} className={classes.amount}>
              <Typography className={classes.rowText} variant="h5" component="p">
                {nutrition[key]}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} sm={12} xs={12}>
              <Divider />
            </Grid>
          </>
        )
      })
      return rows
    }
    return null
  }


  return (
    <Page
      className={classes.root}
      title="Recept"
    >
      <Container maxWidth="lg">
        <Box mt={3}>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            <Grid item xl={12} lg={12} sm={12} xs={12} >
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image={recipe.image_url}
                >
                  <Grid container justifyContent="flex-end" alignItems="flex-end">
                    <Grid item xl={3} lg={3} sm={3} xs={3} >
                      <Typography className={classes.prepTime} variant="h5" component="h2">
                        <b>{recipe.prep_time} min</b>
                      </Typography>
                    </Grid>
                  </Grid>
                </CardMedia>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box mt={-3}  mb={3}>
          <Grid container spacing={6} sx={{ flexGrow: 1 }} justifyContent="center">
            <Grid item xs={11}>
              <Grid container justifyContent="center" spacing={6}>
                <Grid className={classes.roundedGrid} item xl={12} lg={12} sm={12} xs={12} >
                  <Typography variant="h2" component="h2" align="center">
                    {recipe.title}
                  </Typography>
                  <Typography variant="h4" component="h2" align="center">
                    {recipe.subtitle}
                  </Typography>
                  <Box mt={3}>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xl={6} lg={6} sm={6} xs={6} style={{textAlign: "right"}}>
                        <FormControl className={classes.personsForm}>
                          <InputLabel>Aantal Personen</InputLabel>
                          <Select
                            value={persons}
                            label="Persons"
                            onChange={(event) => handlePersonsChange(event.target.value)}
                          >
                            {[...Array(10).keys()].map((i) => (
                              <MenuItem key={`mu-${i+1}`} value={i + 1}>{i + 1}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xl={2} lg={2} sm={3} xs={6} style={{textAlign: "left"}}>
                        <Button
                          id={"abc"}
                          className={classes.addButton}
                          size="small"
                          color="secondary"
                          variant="contained"
                          disabled={disableAdd}
                          onClick={handleAddRecipe}
                        >
                          {disableAdd ? 'Reeds in het menu' : 'Toevoegen'}
                        </Button>
                      </Grid>
                      <Grid item xl={4} lg={4} sm={3} xs={1} style={{textAlign: "left"}}>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid className={classes.roundedGrid} item xl={7} lg={7} sm={12} xs={12} >
                  <Grid className={classes.roundedGrid} item xl={12} lg={12} sm={12} xs={12} >
                    <Typography variant="h3" component="h3" align="center" style={{marginBottom: '1em'}}>
                      Ingrediënten
                    </Typography>
                  </Grid>
                  <Box mx={3}>
                    <Grid container spacing={3} justifyContent="flex-start" alignItems="flex-start">
                      { (Object.keys(ingredients).length > 0) ?
                          ingredientSortOrder.map((category) => {
                            return getIngredients(category)
                          })
                          :
                          'loading...'
                      }
                    </Grid>
                  </Box>
                </Grid>
                <Hidden smDown >
                  <Grid className={classes.roundedGrid} item xl={1} lg={1} sm={12} xs={12}>
                    <Divider orientation='vertical' style={{marginLeft: '50%'}}/>
                  </Grid>
                  <Grid className={classes.roundedGrid} item xl={4} lg={4} sm={12} xs={12} >
                    <Typography variant="h3" component="h3" align="center" style={{marginBottom: '1em'}}>
                      Voedingswaarden
                    </Typography>
                    <Box mx={6}>
                      <Grid container spacing={1} justifyContent="center" alignItems="flex-start">
                          { (Object.keys(nutrition).length > 0) ?
                              getNutrition()
                              :
                              'loading...'
                          }
                      </Grid>
                    </Box>
                  </Grid>
                </Hidden>
                <Grid className={classes.roundedGrid} item xl={12} lg={12} sm={12} xs={12} >
                  <Typography variant="h3" component="h3" align="center" style={{marginBottom: '1em'}}>
                    Instructies
                  </Typography>
                  {
                    <Swiper
                      className={classes.swiperContainer}
                      modules={[Pagination]}
                      spaceBetween={10}
                      slidesPerView='auto'
                      pagination={{ clickable: true }}
                      // onSlideChange={() => console.log('slide change')}
                      // onSwiper={(swiper) => console.log(swiper)}
                    >
                      {instructions.length > 0 ?
                        instructions.map((instr, i) => (
                          <SwiperSlide className={classes.swiperSlide}>
                              <InstructionCard
                                step={instr.step}
                                picture={{source: instr.image_url}}
                                instruction={instr.instruction}
                              />
                          </SwiperSlide>
                        ))
                        :
                        'loading...'
                      }
                    </Swiper>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
}

export default RecipeView;
