import { useState, useEffect } from 'react';
import store from '../store';  //contains access to authentication token

const getEncodedURLParams = (obj) => {
  var params = [];
  for(var p in obj) {
    if (Array.isArray(obj[p])) {
      obj[p].forEach(val => {
        params.push(encodeURIComponent(p) + "=" + encodeURIComponent(val))
      })
    }
    else {
      params.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return params.join("&");
}



const useFetchJson = (url, params={}, refetch=undefined) => {
  const { token } = store.getState().auth;
  const [response, setResponse] = useState(null)
  const [loading, setLoading] = useState(true)
  const [hasError, setHasError] = useState(false)

  let requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': 'Token ' + token,
      'Content-Type': 'application/json'
    }
  }

  const fetchData = () => {
    setLoading(true)

    let fetchUrl = url + '?' + getEncodedURLParams(params);

    fetch(fetchUrl, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      setResponse(data)
      setHasError(false)
      setLoading(false)
    })
    .catch(() => {
      setHasError(true)
      setLoading(false)
    })
  }

  //This useEffect hook will wait for the params or the url to change
  //It is a pointer to the url/param state variables of the calling component
  useEffect(() => {
    fetchData()
  }, [url, params, refetch])

  return [response, loading, hasError]
}

export {
  useFetchJson,
}
