import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes,} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/global/GlobalStyles';
import theme from './theme';
import routes from './routes';

import { RootState, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { useSelector } from "react-redux";

const App = () => {
  const auth = useSelector((state: RootState) => state.auth);
  //const { isLoggedIn } = useSelector((state) => state.auth);
  const routing = useRoutes(routes(auth.account));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <PersistGate persistor={persistor} loading={null}>
        {routing}
      </PersistGate>
    </ThemeProvider>
  );
};

export default App;
