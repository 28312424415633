import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  makeStyles
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';

import MoreMenu from '../menus/MoreMenu';

const useStyles = makeStyles(({
  root: {
    height: '100%',
    maxWidth: '50em'
  },
  listItemAvatar: {
    width: '20%',
    marginRight: '1em',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    marginRight: '1em',
  },
  listItemText: {
    width: '50%',
    cursor: 'pointer',
  },
  listItemTextPrimary: {
    width: '100%',
    fontSize:'0.9em',
    marginRight: '1em',
  },
  personsForm: {
    width: '15%',
  },
  moreMenu: {
    width: '10%',
    paddingLeft: '1em',
  }
}));

const RecipeList = ({ recipes, handleRecipeDetail, handlePersonsChange, handleRemoveRecipe, handleClear, ...rest }) => {
  const classes = useStyles();

  const handleDelete = (recipeId) => {
    handleRemoveRecipe(recipeId)
  };

  return (
    <Card
      className={classes.root}
      {...rest}
    >
      <List>
        {recipes.map((recipe, i) => (
          <ListItem
            divider={i < recipes.length - 1}
            key={recipe.id}
          >
            <ListItemAvatar
              className={classes.listItemAvatar}
              onClick={() => handleRecipeDetail(recipe.id)}
            >
              <img
                alt="Recipe"
                className={classes.image}
                src={recipe.imageUrl}
              />
            </ListItemAvatar>
            <ListItemText
              className={classes.listItemText}
              classes={{primary: classes.listItemTextPrimary}}
              primary={recipe.title}
              secondary={recipe.preptime}
              onClick={() => handleRecipeDetail(recipe.id)}
            />
            <FormControl className={classes.personsForm}>
              <InputLabel>Pers.</InputLabel>
              <Select
                value={recipe.persons}
                label="Persons"
                onChange={(event) => handlePersonsChange(recipe.id, event.target.value)}
              >
                {[...Array(10).keys()].map((i) => (
                  <MenuItem key={`mu-${i}`} value={i + 1}>{i + 1}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <MoreMenu
              className={classes.moreMenu}
              menuData={[{title: 'Verwijder', handleFunction: handleDelete, functionParam: recipe.id}]}
            >
            </MoreMenu>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        display="flex"
        p={2}
      >
        <Grid container justifyContent='flex-start'>
          <Button
            color="secondary"
            startIcon={<ArrowLeftIcon />}
            size="small"
            variant="text"
            onClick={handleClear}
            style={{textAlign: 'left'}}
          >
            Verwijder alles
          </Button>
        </Grid>
        <Grid container justifyContent='flex-end'>
          <Button
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="text"
            href="shoppinglist"
            style={{textAlign: 'right'}}
          >
            winkellijstje
          </Button>
        </Grid>
      </Box>
    </Card>
  );
};

RecipeList.propTypes = {
  className: PropTypes.string
};

export default RecipeList;
