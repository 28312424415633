import React from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/global/Page';

import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import authSlice from "../../store/slices/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  simpleButton: {
    width: '47.5%',
    marginRight: '2.5%'
  }
}));

const LogoutView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/login");
  };

  return (
    <Page
      className={classes.root}
      title="Logout"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box
            mt={3}
            mb={1}
          >
            <Typography
              align="center"
              color="textSecondary"
              variant="body1"
            >
              Are you sure you want to logout?
            </Typography>
          </Box>
          <Box my={2}>
            <Button
              className={classes.simpleButton}
              color="primary"
              size="large"
              variant="contained"
              onClick={handleLogout}
            >
              Yes
            </Button>
            <Button
              className={classes.simpleButton}
              size="large"
              variant="contained"
              onClick={() => navigate("/")}
            >
              No
            </Button>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default LogoutView;
